import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const drawerWidth = 217;
export default makeStyles((theme) => ({
    container: {
        // maxHeight: 600,
        overflowX: "hidden !important",
        overflowY: "hidden !important",
    },
    containerDatos: {
        maxWidth: 800,
        margin: "10px auto auto auto",
        overflowX: "hidden !important",
    },
    containerNorma: {
        // overflowX: "auto",
        // margin: "auto",
        //maxWidth: "100%",
        height: "100vh",
        margin: "10px auto auto auto",
    },
    containerResumen: {
        // overflowX: "auto",
        // margin: "auto",
        //maxWidth: "100%",
        margin: "10px auto auto auto",
    },

    formControl: {
        // margin: theme.spacing(1),
        marginBottom: 7,
        width: "100%",
    },
    inputMaterial: {
        // margin: theme.spacing(1),
        marginBottom: 7,
        width: "100%",
    },
    contentDiv: {
        display: "flex",
    },
    partes: {
        margin: theme.spacing(1),
        width: 300,
    },
    firma: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        // width: 500,
    },
    modal: {
        position: "absolute",
        width: 400,
        height: "80%",
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        left: "35.3%",
        top: "10%",
        overflowY: "scroll",
        zIndex: 500,
    },
    modalUsuario: {
        position: "absolute",
        width: 680,
        height: 360,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        left: "50%",
        marginLeft: -340,
        top: "50%",
        marginTop: -180,
        // overflowY: "scroll",
        zIndex: 500,
    },
    modalUsuarioModifica: {
        position: "absolute",
        width: 680,
        height: 420,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        left: "50%",
        marginLeft: -340,
        top: "50%",
        marginTop: -210,
        // overflowY: "scroll",
        zIndex: 500,
    },

    modalEmpresa: {
        position: "absolute",
        width: 680,
        height: 490,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        left: "50%",
        marginLeft: -340,
        top: "50%",
        marginTop: -245,
        // overflowY: "scroll",
        zIndex: 500,
    },

    modalEvaluacion: {
        position: "absolute",
        width: 400,
        height: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        left: "50%",
        marginLeft: -200,
        top: "50%",
        marginTop: -200,
        // overflowY: "scroll",
        zIndex: 500,
    },
    modalEvaluacionAgregarDoc: {
        position: "absolute",
        width: 600,
        height: 250,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        left: "50%",
        marginLeft: -300,
        top: "50%",
        marginTop: -125,
        // overflowY: "scroll",
        zIndex: 500,
    },

    modalEvaluacionListaDocs: {
        position: "absolute",
        width: 550,
        height: 500,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        left: "50%",
        marginLeft: -275,
        top: "50%",
        marginTop: -250,
        overflowY: "scroll",
        zIndex: 500,
    },
    listaDocs: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    documento: {
        display: "flex",
        marginBottom: "10px",
    },
    botonDocumento: {
        height: "54px",
        width: "60px",

        backgroundColor: "black",
        color: "white",
        "&:hover, &:focus": {
            backgroundColor: "gray",
        },
        "&:active": {
            backgroundColor: "gray",
        },
        // marginLeft: "25px",
        marginRight: "auto",
    },

    modalAuditoria: {
        position: "absolute",
        width: 680,
        height: 370,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        left: "50%",
        marginLeft: -340,
        top: "50%",
        marginTop: -185,
        // overflowY: "scroll",
        zIndex: 500,
    },

    modalDatos: {
        position: "absolute",
        width: 700,
        height: 440,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        left: "50%",
        marginLeft: -350,
        top: "50%",
        marginTop: -170,
        overflowY: "scroll",
        zIndex: 500,
    },
    modalNorma: {
        position: "absolute",
        // width: "20%",
        width: 400,
        // height: "40%",
        minHeight: 300,
        maxHeight: 420,
        // height: 300,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        left: "50%",
        marginLeft: -200,
        top: "50%",
        marginTop: -150,
        overflowY: "scroll",
        zIndex: 500,

        "& textarea": {
            outline: "none",
            resize: "none",
            // fontSize: 14,
            "font-family": ' "Roboto", sans-serif',
        },
    },
    modalParticipantes: {
        position: "absolute",
        width: 400,
        minHeight: 300,
        maxHeight: 420,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        left: "50%",
        marginLeft: -200,
        top: "50%",
        marginTop: -150,
        overflowY: "scroll",
        zIndex: 500,
    },
    boton: {
        backgroundColor: "black !important",
        color: "white !important",
        "&:hover, &:focus": {
            backgroundColor: "gray !important",
        },
        "&:active": {
            backgroundColor: "gray !important",
        },
    },
    botonbuscarpac: {
        backgroundColor: "black",
        color: "white",
        "&:hover, &:focus": {
            backgroundColor: "gray",
        },
        "&:active": {
            backgroundColor: "gray",
        },
        marginLeft: "40px",
        marginTop: "10px",
        height: "100%",
        width: "40%",
    },
    table: {
        // position: "sticky",
        "& thead": {
            background: "black",
            "& th": {
                color: "white",
                background: "black",
                // position: "sticky",
                top: 0,
            },
        },
        "& tbody": {
            "& td": {
                borderRight: "1.5px solid",
                borderRightColor: "#e8e8e8",
                borderColor: "#e8e8e8",
            },
        },
        // overflowX: "hidden",
    },
    tableNorma: {
        backgroundColor: theme.palette.background.paper,
        "& thead": {
            background: "black",
            // position: "sticky",
            "& th": {
                color: "white",
                background: "black",
                // position: "sticky",
                top: 0,
                borderColor: "gray",
                border: "1px solid",
                fontSize: "9.5px",
            },
        },

        "& tbody": {
            "& td": {
                //color: "black",
                padding: "9px",
                borderColor: "gray",
                border: "1px solid",
                fontSize: "11px",
                whiteSpace: "pre-line",

                // textAlign: "justify",
            },
        },
    },
    tablePac: {
        "& thead": {
            background: "black",
            "& th": {
                color: "white",
                background: "black",
                //position: "sticky",
                top: 0,
                border: "1px solid",
            },
        },
        backgroundColor: theme.palette.background.paper,
        "& tbody": {
            "& td": {
                //color: "black",
                border: "1px solid",
                "& textarea": {
                    verticalAlign: "middle",
                    width: "100%",
                    outline: "none",
                    border: "none",
                    resize: "none",
                    fontSize: 14,
                    WebkitBoxSizing: "border-box",
                    MozBoxSizing: "border-box",
                    boxSizing: "border-box",
                    // fontFamily: "Roboto",
                    "font-family": ' "Roboto", sans-serif',
                },
            },
        },
    },
    iconos: {
        cursor: "pointer",
        color: "black",
    },

    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    ///////////////////////////////////////////
    //////////////////////////////////////////
    navegador: {
        display: "flex",
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.standard,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.standard,
        }),
        marginLeft: 0,
    },

    //BUSCADOR
    botonSearch: {
        backgroundColor: "black !important",
        color: "white !important",
        "&:hover, &:focus": {
            backgroundColor: "gray !important",
        },
        "&:active": {
            backgroundColor: "gray !important",
        },
        marginRight: "30px",
    },
    search: {
        display: "flex",
        // justifyContent: "center",
    },
    buscador: {
        // marginRight: "auto",
        marginLeft: "auto",
        // position: "right",
        width: "400px",
    },
    ocultar: {
        display: "none",
    },

    bar: {
        // width: "100%",
        backgroundColor: theme.palette.background.paper,
        width: `calc(100vw - 400px)`,
    },

    pacExpediente: {
        display: "flex",
    },

    //APPBAR
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: 120,
        }),
        backgroundColor: "black",
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.standard,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },

    //APPBAR AUDITORIA
    appBarAudit: {
        width: `calc(100vw - 350px)`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: 120,
        }),
    },
    appBarShiftAudit: {
        width: `calc(100vw - 450px)`,
        // marginLeft: 400,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.standard,
        }),
    },
}));

export const Estilos = makeStyles((theme) => ({
    loginTitle: {
        fontSize: "45px",
        textAlign: "center",
    },
    loginForm: {
        display: "flex",
        flexDirection: "column",
        width: "90%",
    },
    container: {
        width: "100%",
        height: "100vh",
        background: "black",
        color: "white",
    },
    paper: {
        height: "85%",
        width: "40%",
        paddingTop: theme.spacing(8),
        display: "flex",
        margin: "auto",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "@media(max-width:700px)": {
            // eslint-disable-line no-useless-computed-key
            width: "80% !important",
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    textfield: {
        border: "1px white",
    },

    colorText: {
        color: `#FFFFFF !important`,
    },

    check: {
        border: "#FFFFFF",
    },
}));

export const CssTextField = withStyles({
    root: {
        "& input:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0 1000px #000000 inset !important",
            "-webkit-text-fill-color": "white !important",
        },

        "& input": {
            color: "white",
        },

        "& label.Mui-focused": {
            color: "white",
        },

        "& label": {
            color: "gray",
        },
        "&:hover label": {
            color: "#CAB5B5",
        },

        "&:hover label.Mui-focused": {
            color: "white",
        },

        "& .MuiInput-underline:after": {
            borderBottomColor: "white",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "gray",
            },
            "&:hover fieldset": {
                borderColor: "#CAB5B5",
            },
            "&.Mui-focused fieldset": {
                borderColor: "white",
            },
        },
    },
})(TextField);
