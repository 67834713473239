import React, { useContext } from "react";
import { Snackbar, SnackbarContent } from "@material-ui/core";
import { AlertContext } from "../context/AlertContext";

const Alerta = () => {
    const { snack, handleClose } = useContext(AlertContext);

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            open={snack.state}
            onClose={handleClose}
        >
            <SnackbarContent
                style={{
                    backgroundColor: snack.colorbackgr,
                    color: snack.colortext,
                    margin: "auto",
                }}
                message={snack.mensaje}
            />
        </Snackbar>
    );
};

export default Alerta;
