import React, { createContext, useState } from "react";

export const UsuarioContext = createContext(null);

const UsuarioProvider = (props) => {
    const [user, setUser] = useState(null);

    return (
        <UsuarioContext.Provider value={{ user, setUser }}>
            {props.children}
        </UsuarioContext.Provider>
    );
};

export default UsuarioProvider;
