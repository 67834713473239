import axios from "axios";

let helper = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
});

helper.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

let helpernotoken = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
});

export { helper, helpernotoken };
